<template>
    <div class="coursetext">
        <div class="coursetext_child">
            <div class="coursetext_left">
                <div class="coursetext_left_header">
                    <div style="font-size: 1.8em; font-weight: 600;">
                        店铺运营商品管理须知
                    </div>
                    <div class="coursetext_left_header_right">
                        <div>
                            <img src="../assets/leaning/xin.png" alt=""
                                style="width: 18px; height: 18px; object-fit: contain;" />关注课程
                        </div>
                        <div style="padding-left: 20px;">
                            <img src="../assets/leaning/share.png" alt=""
                                style="width: 18px; height: 18px; object-fit: contain;" />分享课程
                        </div>

                    </div>
                </div>
                <div>
                    发布时间： 2022-12-10 标签： 商品信息质量分,商品运营能力,商品资质,商品发布流程,初阶
                </div>
                <div class="satisfaction">
                    <el-radio-group v-model="satisfaction" class="ml-4">
                        <el-radio label="1" size="large"><img src="../assets/leaning/differ.png"
                                alt="">&nbsp;&nbsp;非常不满意</el-radio>
                        <el-radio label="2" size="large"><img src="../assets/leaning/letterdiffer.png"
                                alt="">&nbsp;&nbsp;不满意</el-radio>
                        <el-radio label="3" size="large"><img src="../assets/leaning/nomal.png"
                                alt="">&nbsp;&nbsp;一般</el-radio>
                        <el-radio label="4" size="large"><img src="../assets/leaning/good.png"
                                alt="">&nbsp;&nbsp;满意</el-radio>
                        <el-radio label="5" size="large"><img src="../assets/leaning/verygood.png"
                                alt="">&nbsp;&nbsp;非常满意</el-radio>
                    </el-radio-group>
                </div>
                <div class="text_con">
                    <img src="../assets/leaning/text1.jpg" alt="" />
                </div>
            </div>
            <div class="coursetext_right">
                <div class="coursetext_right_img">
                    <img src="../assets/leaning/xuelogo.png" alt="" />
                </div>
                <div class="coursetext_right_title">
                    TA的文章
                </div>
                <div class="coursetext_right_text">
                    <ul>
                        <li>营销活动常见活动</li>
                        <li>营销工具常见营销工具</li>
                        <li>店铺运营商品管理须知</li>
                        <li>合规商品必知规则</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup >
import { ref, reactive } from 'vue'
const satisfaction = ref("")
</script>

<style scoped lang="scss">
.coursetext {
    width: 100%;
    min-height: 1200px;
    display: flex;
    justify-content: center;

    .coursetext_child {
        width: 70%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .coursetext_left {
            flex: 5;
            min-height: 1200px;
            background-color: white;
            padding: 20px;

            .coursetext_left_header {
                display: flex;
                height: 6em;
                padding: 0px 30px;
                justify-content: space-between;
                align-items: center;

                .coursetext_left_header_right {
                    display: flex;
                    align-items: center;



                    img {
                        vertical-align: middle;
                    }
                }
            }

            .satisfaction {
                margin-top: 10px;
                width: 100%;
                height: 5em;
                background-color: #f1f1f1;
                display: flex;
                align-items: center;

                img {
                    vertical-align: middle;
                }
            }
            .text_con{
                width: 100%;

            }
        }

        .coursetext_right {
            flex: 1;
            height: 20em;
            background-color: white;
            margin-left: 20px;
            padding: 5px;

            .coursetext_right_img {
                padding: 5px 8px;
                height: 8em;
                display: flex;
                align-items: center;
                border-bottom: 1px solid gainsboro;
            }

            .coursetext_right_title {
                font-size: 1.4em;
                height: 50px;
                display: flex;
                align-items: center;
                font-weight: 600;
            }

            .coursetext_right_text {
                line-height: 22px;
                font-size: 1.1em;
            }
        }
    }
}</style>